body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  margin-left: 3px;
  margin-right: 3px;
}

.login {
  margin-top: 50px;
}

.error-page {
  margin-top: 100px;
}

/* Bootstrap 5 legacy */
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem;
}

.navbar {
  padding: 0.5rem 1rem;
}

a { text-decoration: none; }

#dashboard-title {
  margin-left: 25px;
}

.container-list {
  margin-top: 30px;
}

.container-list tbody {
  cursor: pointer;
}

.container-item {
  margin-top: 30px;
}

.container-item tbody {
  cursor: pointer;
}

.users-list {
  margin-top: 30px;
}

.users-list tbody {
  cursor: pointer;
}

.roles-list {
  margin-top: 30px;
}
.roles-list tbody {
  cursor: pointer;
}

.role {
  margin-left: 0px;
  margin-right: 4px;
}

.locations-list {
  margin-top: 30px;
}
.locations-list tbody {
  cursor: pointer;
}

.location {
  margin-left: 0px;
  margin-right: 4px;
}

.task-template {
  margin-left: 0px;
  margin-right: 4px;
}

.teamleader-custom-field-definition {
  margin-left: 0px;
  margin-right: 4px;
  cursor: pointer;
}

.task-blocked .bar {
  fill: #adb5bd !important;
}
.task-unassigned .bar {
  fill: #00cc00 !important;
}
.task-started .bar {
  fill: #0dcaf0 !important;
}
.task-finished .bar {
  fill: darkblue !important;
}
.task-flagged .bar {
  fill: red !important;
}

.datatable-orderby {
  cursor: 'pointer';
}

.divider {
  border-top: 1px solid #ececec;
  margin-left: 20px;
  margin-right: 20px;
}

.nav-link {
  cursor: pointer;
  /* text-decoration: none; */
  color: rgba(26,26,26,.75);
}

.vertical-space {
  margin-top: 20px;
}

/* Table styling */
.container-table {
  max-width: fit-content;
}

.task-row-flagged {
  background: #f5c2c7;
}
